import { initializeShopifyApolloClient } from "../data/shopify/services/shopifyApolloClient";

let lang;

export default function setLang(lang_ = "us") {

    if (lang_ === "[lang]") { // this happens when fallback page is rendered
        return;
    }

    initializeShopifyApolloClient(lang_);
    lang = lang_;
}

export function getLang() {
    return lang;
}

export function getLangFromRouter(router) {
    if (router === null) {
        return "us"
    } else {
        return router.query.lang ?? 'us'
    }
}
