import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch';
import {getLang} from '../../../helpers/setLang'
import introspectionQueryResultData from './fragmentTypes.json';

const customFetch = (uri, options) => {
    return fetch(uri, options).catch(error => {
        console.log("###ERROR", error)
        const stringifiedError = new String(error)
        if (stringifiedError.includes('aborted')) {
            throw new Error('Aborted')
        }
        throw error
    })
};
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}
let shopifyApolloClients = {};

const map = {
    pl: {
        token: process.env.NEXT_PUBLIC_ENV_STORE_FRONT_ACCESS_TOKEN_PL,
        uri: process.env.NEXT_PUBLIC_ENV_URI_PL
    },
    eu: {
        token: process.env.NEXT_PUBLIC_ENV_STORE_FRONT_ACCESS_TOKEN_EU,
        uri: process.env.NEXT_PUBLIC_ENV_URI_EU
    },
    uk: {
        token: process.env.NEXT_PUBLIC_ENV_STORE_FRONT_ACCESS_TOKEN_UK,
        uri: process.env.NEXT_PUBLIC_ENV_URI_UK
    },
    us: {
        token: process.env.NEXT_PUBLIC_ENV_STORE_FRONT_ACCESS_TOKEN_US,
        uri: process.env.NEXT_PUBLIC_ENV_URI_US
    }
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});


export function initializeShopifyApolloClient(lang) {
  if (typeof window === "object" && shopifyApolloClients[lang]) { // Don't reinitialize on the browser when instance was already created
    return;
  }

    if (process.env.NEXT_PUBLIC_DATA_LAYER !== "shopify") {
        return;
    }

    // console.log('initalize apollo!');

    // console.log('### INIT APOLLO ###');

    const config = map[lang];

    const httpLink = createHttpLink({
        uri: config.uri,
        fetch: customFetch
    });

    const middlewareLink = setContext(() => {
        return {
            headers: {
                'X-Shopify-Storefront-Access-Token': config.token
            }
        }
    });

    // console.log(`Initialize apollo client: ${lang}, ${config.token}, ${config.uri}`);

    // console.log('(initializeShopifyApolloClient) lang: ', lang, 'IS_BUILD: ', process.env.IS_BUILD);

    // If IS_BUILD === true, we want caching to prevent calling Shopify API all the time with the same requests.
    // However, IS_BUILD should be false in the browser and particularly during static site regeneration (inMemoryCache would prevent from updating data)

    let cache = new InMemoryCache({
        // cacheRedirects: false,
        fragmentMatcher,
        resultCaching: process.env.IS_BUILD === "true"
    });

    shopifyApolloClients[lang] = new ApolloClient({
        link: middlewareLink.concat(httpLink),
        cache,
        defaultOptions
    });
}


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const shopifyApolloGetter = (lang) => {

    if(typeof lang === "undefined") {
        lang = getLang();
    }

    if (!shopifyApolloClients[lang]) {
        initializeShopifyApolloClient(lang)
    }

    return shopifyApolloClients[lang];
};

export async function graphqlQuery(queryParams, lang) {
    const client = shopifyApolloGetter(lang);

    let isFirst = true;
    let response;

    do {
        if (!isFirst) {
            console.log('Error while fetching, sleeping 5s...', lang);
            await sleep(20000);
            console.log('Wake up');
        }
        // console.log('\x1b[31m%s\x1b[0m', 'fetching data');  //cyan

        // try {
        //     response = client.readQuery(queryParams);
        //     console.log('######### HIT!!!!!!!!! ###########');
        // } catch(err) {
        //     console.log('miss :(');
        //
        //     response = await client.query(queryParams);
        // }

        response = await client.query(queryParams).catch(err => {
            console.log(err);
            return undefined
        });


        if (response && response.errors) {
            console.log(response.errors);
        }

        // console.log('data fetch finished');

        isFirst = false;

    } while (!response || !response.data);

    return response;
}

export default shopifyApolloGetter

